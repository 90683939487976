<template>
<div>
    <div class="card">
        <!-- Logo -->
        <div class="card-header pt-4 pb-4 text-center ">
            <span><img src="@/assets/images/new_logo.svg" alt="" height="52"></span>
        </div>

        <div class="card-body p-4">  
            <form action="#">
                <div class="text-center w-75 m-auto">
                    <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Reset Password</h4>
                    <p class="text-muted mb-4">Enter your email address and we'll send you an email with instructions to reset your password.</p>
                </div>

                <div class="form-group">
                    <label for="emailaddress">Email address</label>
                    <input class="form-control" type="email" id="emailaddress" required placeholder="Enter your email" v-model="form.email">
                </div>

                <div class="form-group mb-0 text-center">
                    <button v-if="!loading" class="btn btn-primary" type="submit" @click.prevent="submitForm()"> Reset Password </button>
                    <button v-else class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                        Loading...
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 text-center">
            <p class="text-muted">Back to <router-link :to="{name: 'Login'}" class="text-muted ml-1"><b> Sign in page </b></router-link></p>
        </div> <!-- end col -->
    </div>
</div>
</template>


<script>
import log from '@/plugins/log'
import { to } from '@/plugins/utils'


export default {
	name: 'PasswordRecover',
    data: () => {
        return {
            form: {
                email: ''
            },
            loading: false
        }
    },
    methods: {
        async submitForm() {
            if(this.loading) return $.NotificationApp.send("Error", "Please wait", "top-right", "", "error")

            if(!this.form.email) return $.NotificationApp.send("Error", "Enter email", "top-right", "", "error")

            this.loading = true

            var [err, response] = await to( this.$http.post('/auth/setup', this.form) )
            if(err) {
                this.loading = false
                log.error('/views/empty/PasswordRecover', 'methods.submitForm', '$http.request', err)
                return $.NotificationApp.send("Error", err.message, "top-right", "", "error")
            }
            if(!response.data.success) {
                this.loading = false
                log.error('/views/empty/PasswordRecover', 'methods.submitForm', '$http.response', response.data)
                return $.NotificationApp.send("Error", response.data.error, "top-right", "", "error")
            }
            this.loading = false

            this.$store.commit('set_app_token', response.data.accessToken)
            this.$http.defaults.headers.common['Authorization'] = response.data.accessToken;

            this.$store.dispatch('get_status');
            this.$router.push('/');
        }
    }
};
</script>
